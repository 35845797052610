import {
  Alert,
  Button,
  ColumnLayout,
  Container,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Header,
  Link,
  Multiselect,
  SelectProps,
  SpaceBetween,
  TextContent,
  Toggle,
} from "@amzn/awsui-components-react/polaris"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import accountsApi from "api/accounts"
import { OpportunityRequest } from "api/opportunities"
import { ProfileRequest } from "api/profiles"
import { TaskRequest } from "api/tasks"
import { Analytics } from "aws-amplify"
import { AxiosError } from "axios"
import GeoRegionSelector from "components/GeoRegionSelector"
import PartnerFormField from "components/PartnerFormField"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { useProfile } from "context/ProfileContext"
import { useWorkstreamCatalog, WorkstreamItemLookup } from "context/WorkstreamCatalogContext"
import { Formik, FormikHelpers, FormikProps } from "formik"
import { Product } from "models/account"
import { OPPORTUNITY_STAGE } from "models/opportunity"
import { ProfileData } from "models/profile"
import { OmitWorkstreamId } from "models/shared"
import { WorkstreamItem } from "models/workstream"
import moment from "moment"
import FieldContent from "pages/CreateUpdateTask/FieldContent"
import { DEFAULT_TASK } from "pages/CreateUpdateTask/FormContent"
import ErrorFocus from "pmsa-polaris/components/ErrorFocus"
import Fieldset from "pmsa-polaris/components/Fieldset"
import { DatePickerFormField, InputFormField, SelectFormField, TextAreaFormField } from "pmsa-polaris/components/FormFields"
import LoadingHeader from "pmsa-polaris/components/LoadingHeader"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar, { FlashMessage } from "pmsa-polaris/hooks/useFlashbar"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { toOptions } from "pmsa-polaris/utils"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import routes, { routeParams } from "routes"

import topics from "./tools-topics"
import getValidationSchema from "./validationSchema"

const { sfdcBaseUrl } = config

type TaskFormValues = Omit<OmitWorkstreamId<TaskRequest>, "milestone"> & {
  milestoneId: string
}

export type OpportunityFormValues = OmitWorkstreamId<OpportunityRequest> & {
  accountName: string
}

type OpportunityTaskFormValues = {
  opportunity: OpportunityFormValues
  tasks: TaskFormValues[]
}

type ProfileFormValues = {
  profile: ProfileRequest
}

interface WorkstreamOption {
  label: string
  value: string
  description: string
}

interface WorkstreamGroup {
  label?: string
  options: WorkstreamOption[]
}

const DESIGN_WIN_WORKSTREAM_NAME = "[Design Win] Partner Offering"

const DEFAULT_FORM_VALUES = {
  opportunity: {
    accountId: "",
    accountName: "",
    closeDate: moment().add(3, "months").format("YYYY-MM-DD"),
    description: "",
    designWinDetail: "",
    externalLink: "",
    geo: "",
    goals: "",
    name: "",
    nextStep: "",
    opportunityLineItems: [],
    owner: "",
    region: "",
    stageName: OPPORTUNITY_STAGE[0],
    title: "",
    workstreamName: "",
  },
  tasks: [],
}

const WORKSTREAM_TYPE = {
  competency: "Partner Competency",
  ambassador: "AWS Ambassador Program",
} as const

const stageOptions = toOptions(...OPPORTUNITY_STAGE)

const toProductMultiSelectOptions = (productList: Product[]) =>
  productList.map((product) => ({
    value: product.productId,
    label: product.name || "Unknown",
    description: `APN Solution ID: ${product.apnOfferingId} - FTR Status: ${product.ftrStatus}`,
  }))

const findWorkstreamByOptionLabel = (workstreamOptions: WorkstreamGroup[], searchLabel: string): WorkstreamGroup | null => {
  // Loop through each top-level group
  for (const group of workstreamOptions) {
    // Check if any option in the current group matches the search label
    const hasMatchingOption = group.options.some((option) => option.label === searchLabel)

    if (hasMatchingOption) {
      return group
    }
  }
  return null
}

const FormContent: React.FC = () => {
  const { id } = useParams<{
    id?: string
  }>()

  const navigate = useNavigate()

  const { setContext } = useAppContext()

  const { profileData, profileLoading, profileError } = useProfile()
  const { workstreamOptions, workstreamsLoading, streamsByName } = useWorkstreamCatalog()
  const { opportunityList, taskList, createOpportunity, updateOpportunity, getOpportunity, createTask, getOpportunityList } = useOpportunityTaskContext()

  const isLoading = opportunityList.loading || taskList.loading || workstreamsLoading || profileLoading

  const setFlashMessages = useFlashbar()

  const opportunity = id && opportunityList.data ? opportunityList.data.find((opp) => opp.id === id) : undefined
  const [searchParams] = useSearchParams()
  const [marketplaceOffer, setMarketplaceOffer] = useState(false)
  const [marqueeDesignWin, setMarqueeDesignWin] = useState(false)
  const [apnProducts, setApnProducts] = useState([] as SelectProps.Options | undefined)
  const [accountFieldChanged, setAccountFieldChanged] = useState(false)
  const [selectedApnProducts, setSelectedApnProducts] = useState<OptionDefinition[]>([])
  const [forceGetProducts, setForceGetProducts] = useState(false)
  const [workstreamOptionsSeen, setWorkstreamOptionsSeen] = useState(workstreamOptions)

  // account products api
  const [{ loading: productsLoading, data: accountProducts, error: productsError }, getProducts] = usePromise(accountsApi.products)

  // set initial values from default or searchParams
  const [initialValues, setInitialValues] = useState<OpportunityTaskFormValues>({
    tasks: DEFAULT_FORM_VALUES.tasks,
    opportunity: {
      ...DEFAULT_FORM_VALUES.opportunity,
      ...Object.fromEntries(searchParams),
    },
  })

  const { accountId: initialAccountId, accountName: initialAccountName } = initialValues.opportunity

  const [initialPartnerOptions, setInitialPartnerOptions] = useState<OptionDefinition[]>(
    (initialAccountId &&
      initialAccountName && [
        {
          label: initialAccountName,
          value: initialAccountId,
        },
      ]) ||
      []
  )

  // set tooltip sidebar when 'info' button is clicked
  const handleInfoClicked = (toolsTopic: keyof typeof topics) => (e: CustomEvent<BaseNavigationDetail>) => {
    e.preventDefault()
    setContext({ toolsOpen: true, toolsTopic })
  }

  useEffect(() => {
    if (workstreamsLoading) return
    if (workstreamOptions) {
      if (profileData?.freeFormNames) {
        const filteredOptions = findWorkstreamByOptionLabel(workstreamOptions as WorkstreamGroup[], DESIGN_WIN_WORKSTREAM_NAME)
        if (filteredOptions) {
          setWorkstreamOptionsSeen([filteredOptions] as WorkstreamGroup[])
        } else {
          setWorkstreamOptionsSeen(workstreamOptions as WorkstreamGroup[])
        }
      } else {
        setWorkstreamOptionsSeen(workstreamOptions as WorkstreamGroup[])
      }
    }
  }, [workstreamOptions, profileData, workstreamsLoading])

  // effect to set current opportunity
  useEffect(() => {
    if (!id) {
      setInitialValues((prevState) => ({
        opportunity: {
          ...prevState.opportunity,
          geo: profileData?.geo ?? "",
          region: profileData?.region ?? "",
          workstreamName: profileData?.freeFormNames ? DESIGN_WIN_WORKSTREAM_NAME : prevState.opportunity.workstreamName,
          title: profileData?.freeFormNames ? prevState.opportunity.name : prevState.opportunity.title,
        },
        tasks: [],
      }))
    }
  }, [id, profileData, getOpportunity])

  // effect to update initial values from current opportunity
  useEffect(() => {
    if (isLoading) return
    if (id && !opportunity) {
      getOpportunityList()
    }

    if (opportunity) {
      setInitialPartnerOptions([
        {
          label: opportunity.accountName,
          value: opportunity.accountId,
        },
      ])

      opportunity.workstreamName === DESIGN_WIN_WORKSTREAM_NAME && setForceGetProducts(true)

      opportunity.designWinDetail === "Marketplace Listing" && setMarketplaceOffer(true)
      opportunity.goals === "Marquee Design Win" && setMarqueeDesignWin(true)

      const extraOppProps = profileData?.freeFormNames
        ? {
            workstreamName: DESIGN_WIN_WORKSTREAM_NAME,
            goals: opportunity.goals ?? "",
            title: opportunity.name,
          }
        : {
            workstreamName: opportunity.workstreamName ?? "",
            geo: opportunity.geo ?? profileData?.geo ?? "",
            region: opportunity.region ?? profileData?.region ?? "",
            goals:
              opportunity.workstreamName === DESIGN_WIN_WORKSTREAM_NAME && (!opportunity.goals || opportunity.goals === "")
                ? "Design Win"
                : opportunity.goals ?? "",
          }
      setInitialValues({
        opportunity: {
          ...opportunity,
          ...extraOppProps,
        },
        tasks: [],
      })
    }
  }, [opportunity, profileData, getOpportunityList, id, isLoading])

  const handleSubmit = useCallback(
    async (
      { opportunity: { workstreamName, ...opportunity }, tasks }: OpportunityTaskFormValues,
      { setSubmitting }: FormikHelpers<OpportunityTaskFormValues>
    ) => {
      try {
        if (opportunity) {
          const { geo, region } = opportunity

          const parsedWorkstreamId =
            streamsByName && streamsByName.workstreams[workstreamName] && streamsByName.workstreams[workstreamName].id.split(">")[1]
              ? streamsByName.workstreams[workstreamName].id.split(">")[0]
              : streamsByName?.workstreams[workstreamName].id

          const oppLineItems = selectedApnProducts ? selectedApnProducts.map((product) => product.value || "") : []
          const opportunityRequest: OpportunityRequest = {
            ...opportunity,
            workstreamId: parsedWorkstreamId ?? "",
            opportunityLineItems: oppLineItems,
            geo,
            region,
          }

          if (id) {
            await getOpportunity((await updateOpportunity(id, opportunityRequest)).id)
            setFlashMessages([
              {
                content: "Opportunity updated successfully",
                presist: true,
                type: "success",
              },
            ])

            navigate(routeParams.opportunitiesDetails({ id }))
          } else {
            const newOpportunity = await getOpportunity((await createOpportunity(opportunityRequest)).id)

            const flashErrors: FlashMessage[] = []

            await Promise.all(
              tasks.map(async ({ workstreamName, ...task }, index) => {
                const taskRequest: TaskRequest = {
                  ...task,
                  geo,
                  region,
                  milestone: {
                    id: "100",
                    name: "Execute",
                  },
                  relatedId: newOpportunity.id,
                }
                try {
                  await createTask(taskRequest)
                } catch (error) {
                  console.error(error)
                  flashErrors.push({
                    content: "There was an issue with creating task.",
                    presist: true,
                    type: "error",
                  })
                }
              })
            )

            setFlashMessages([
              {
                presist: true,
                type: "success",
                content: "Opportunity created successfully",
              },
              ...flashErrors,
            ])

            navigate(routeParams.opportunitiesDetails({ id: newOpportunity.id }))
          }
        }
      } catch (error) {
        const err = error as AxiosError<{ statusCode: number; message: string }>
        if (err.response && err.response.data && err.response.data.statusCode == 403 && err.response.data.message == "user does not own record") {
          sfdcBaseUrl

          const errmsg = `Only records that you own can be updated.  If you need to change the opportunity owner, navigate to ${sfdcBaseUrl}/${id}`
          setFlashMessages([
            {
              type: "error",
              content: errmsg,
            },
          ])
        } else {
          setFlashMessages([
            {
              type: "error",
              content: "There was an issue with creating opportunity.",
            },
          ])
        }
      } finally {
        setSubmitting(false)
        const event = Analytics.record({ name: "submitted opportunity" })
      }
    },
    [streamsByName, selectedApnProducts, id, updateOpportunity, getOpportunity, setFlashMessages, navigate, createOpportunity, createTask]
  )

  const [prevWorkstream, setPrevWorkstream] = useState("")

  const validationSchema = useMemo(() => getValidationSchema(profileData?.freeFormNames ?? false), [profileData?.freeFormNames])

  const pageTitle = id ? "Update Opportunity" : "Create Opportunity"

  const formikRef = useRef<FormikProps<OpportunityTaskFormValues>>(null)

  useEffect(() => {
    if (
      formikRef.current &&
      formikRef.current.values.opportunity.accountId &&
      formikRef.current.values.opportunity.workstreamName === DESIGN_WIN_WORKSTREAM_NAME &&
      !productsError
    ) {
      if ((accountFieldChanged || !accountProducts) && !productsLoading && !productsError) {
        getProducts(formikRef.current.values.opportunity.accountId)
        setAccountFieldChanged(false)
      }
    } else if (opportunity) {
      if (((opportunity.opportunityLineItems && opportunity.opportunityLineItems.length > 0) || forceGetProducts) && !productsError) {
        !accountProducts && !productsLoading && getProducts(opportunity.accountId)
      }
    } else {
      setApnProducts([])
    }
  }, [prevWorkstream, accountFieldChanged, getProducts, opportunity, accountProducts, productsLoading, forceGetProducts, productsError])

  useEffect(() => {
    if (productsError || productsLoading) {
      return
    }

    if (accountProducts) {
      if (opportunity && opportunity.opportunityLineItems && opportunity.opportunityLineItems.length > 0) {
        const multiSelect = toProductMultiSelectOptions(accountProducts)
        setApnProducts(multiSelect)
        setSelectedApnProducts(multiSelect.filter((product) => opportunity!.opportunityLineItems!.includes(product.value)))
      } else {
        setApnProducts(toProductMultiSelectOptions(accountProducts))
      }
    }
  }, [productsLoading, productsError, accountProducts, opportunity])

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, errors, values: { tasks, opportunity: oppFormValues }, isSubmitting, setFieldValue }: FormikProps<OpportunityTaskFormValues>) => {
        const handleAddTaskClick = () => {
          setFieldValue("tasks", [...tasks, { ...DEFAULT_TASK, relatedId: "n/a" }])
        }

        const handleTaskDelete = (index: number) => () => {
          const newTasks = [...tasks]
          newTasks.splice(index, 1)

          setFieldValue("tasks", newTasks)
        }

        const selectedOpportunityWorkstream: WorkstreamItem | undefined = streamsByName?.workstreams[oppFormValues.workstreamName]

        const titleOptions = streamsByName?.workstreams[oppFormValues.workstreamName]?.titleOptions?.map((v) => ({
          value: v,
        }))

        const getOpportunityName = (
          profileData: Readonly<ProfileData> | undefined,
          loading: boolean,
          oppFormValues: OpportunityFormValues,
          streamsByName: WorkstreamItemLookup | undefined
        ): string => {
          if (profileData?.freeFormNames) {
            return oppFormValues.title
          }

          if (loading) {
            return "-"
          }

          const nameParts = [
            oppFormValues.geo || "[Geo]",
            oppFormValues.region || "[Region]",
            getFiscalYearString(oppFormValues.closeDate) || "[FY--]",
            "PTNR",
            streamsByName && oppFormValues.workstreamName
              ? streamsByName.workstreams[oppFormValues.workstreamName].id.split(">")[0].split("+")[0]
              : "[Opportunity Type]",
            oppFormValues.title || "[Title]",
          ]

          return nameParts.join("-")
        }

        const getFiscalYear = (closeDateString: string) => parseInt(new Date(closeDateString).getFullYear().toString().substring(2))

        const getFiscalYearString = (closeDateString: string) => `FY${getFiscalYear(closeDateString)}`

        const existingTitle =
          streamsByName && oppFormValues.workstreamName ? streamsByName.workstreams[oppFormValues.workstreamName].id.split(">")[1] : undefined

        oppFormValues.title = existingTitle || oppFormValues.title

        const opportunityName = getOpportunityName(profileData, opportunityList.loading, oppFormValues, streamsByName)

        const workstreamChanged = (e: any) => {
          if (
            prevWorkstream === "Service Delivery Program" ||
            prevWorkstream === "Partner Competency" ||
            prevWorkstream === "AWS Ambassador Program" ||
            (streamsByName && oppFormValues.workstreamName && streamsByName.workstreams[oppFormValues.workstreamName].id.split(">")[1])
          ) {
            setFieldValue("opportunity.title", "")
            setFieldValue("opportunity.name", "")
          }

          setPrevWorkstream(e.detail.selectedOption.value)
          marketplaceOfferChange(false)
          marqueeDesignWinChange(false, e.detail.selectedOption.value)
        }

        const marketplaceOfferChange = (e: boolean) => {
          setMarketplaceOffer(e)
          e ? setFieldValue("opportunity.designWinDetail", "Marketplace Listing") : setFieldValue("opportunity.designWinDetail", "")
        }

        const marqueeDesignWinChange = (e: boolean, workstream?: string) => {
          setMarqueeDesignWin(e)

          if (workstream && workstream !== DESIGN_WIN_WORKSTREAM_NAME) {
            setFieldValue("opportunity.goals", "")
          } else {
            e ? setFieldValue("opportunity.goals", "Marquee Design Win") : setFieldValue("opportunity.goals", "Design Win")
          }
        }

        const apnProductsChange = (e: any) => {
          setSelectedApnProducts(e.detail.selectedOptions)
          setFieldValue(
            "opportunity.opportunityLineItems",
            e.detail.selectedOptions.map((product: any) => product.value)
          )
        }

        const titleChange = (e: string) => {
          setFieldValue("opportunity.title", e) // need to ensure latest title
          const opportunityName = getOpportunityName(profileData, opportunityList.loading, { ...oppFormValues, title: e }, streamsByName)
          setFieldValue("opportunity.name", opportunityName)
        }

        return (
          <form onSubmit={handleSubmit} className={isLoading ? "loading" : undefined}>
            <Form
              header={<LoadingHeader loading={isLoading}>{pageTitle}</LoadingHeader>}
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <NavigationButton variant="link" href={(id && routeParams.opportunitiesDetails({ id })) || routes.home} disabled={isSubmitting || isLoading}>
                    Cancel
                  </NavigationButton>
                  <Button variant="primary" formAction="submit" disabled={isSubmitting || isLoading} loading={isSubmitting || isLoading}>
                    {pageTitle}
                  </Button>
                </SpaceBetween>
              }
              errorText={
                opportunityList.error
                  ? "We can't process the request right now because of an issue with the server. Try again later.  If the problem persists, contact support"
                  : ""
              }
            >
              <ErrorFocus />
              {errors.opportunity?.opportunityLineItems && (
                <Alert statusIconAriaLabel="Error" type="error" header="Validation Error">
                  Add at least one solution if the opportunity is in Complete stage. Add a product or switch to a Marketplace offer.
                </Alert>
              )}
              <SpaceBetween size="l">
                {!profileData?.freeFormNames && (
                  <Container>
                    <FormField label="Opportunity Name" description="Formatted opportunity name based selections">
                      <TextContent>
                        <strong>{opportunityName}</strong>
                      </TextContent>
                    </FormField>
                  </Container>
                )}
                <ExpandableSection defaultExpanded={true} variant="container" headerText="Opportunity">
                  <SpaceBetween size="l">
                    <SelectFormField
                      name="opportunity.workstreamName"
                      label="Opportunity Type"
                      filteringType="auto"
                      options={workstreamOptionsSeen}
                      onChange={workstreamChanged}
                      required
                      info={
                        <Link variant="info" onFollow={handleInfoClicked("helpOpportunityType")}>
                          Info
                        </Link>
                      }
                      description="Choose the appropriate Workstream from the Catalog"
                    />

                    <PartnerFormField
                      label="Account Name (Partner)"
                      initialOptions={initialPartnerOptions}
                      name="opportunity.accountId"
                      onChange={(e) => {
                        setAccountFieldChanged(true)
                      }}
                      description="Enter the Partner Name associated with the workstream or enter their SPMS ID. Exact match search if within double quotes."
                      required
                    />

                    {(titleOptions && titleOptions?.length > 0 && (
                      <SelectFormField
                        filteringType="auto"
                        label={selectedOpportunityWorkstream?.name === WORKSTREAM_TYPE.competency ? "Competency" : "Service Delivery"}
                        name="opportunity.title"
                        options={titleOptions}
                        description={
                          selectedOpportunityWorkstream?.name === WORKSTREAM_TYPE.competency
                            ? "Choose the appropriate APN Competency title"
                            : "Choose the appropriate APN Service Delivery Program title"
                        }
                        required
                        onChange={(e) => {
                          setFieldValue(
                            "opportunity.name",
                            getOpportunityName(profileData, opportunityList.loading, { ...oppFormValues, title: e.detail.selectedOption.value }, streamsByName)
                          )
                        }}
                      />
                    )) || (
                      <InputFormField
                        key={selectedOpportunityWorkstream?.name || "opptitlekey"}
                        name="opportunity.title"
                        inputMode="text"
                        label={selectedOpportunityWorkstream?.name === WORKSTREAM_TYPE.ambassador ? "Ambassador Full Name" : "Title"}
                        description={
                          selectedOpportunityWorkstream?.name === WORKSTREAM_TYPE.ambassador
                            ? "Enter the full name of the Ambassador"
                            : "Enter the title of the opportunity."
                        }
                        required
                        disabled={selectedOpportunityWorkstream?.id.includes(">")}
                        onChange={(e) => {
                          titleChange(e.detail.value)
                        }}
                      />
                    )}

                    {!profileData?.freeFormNames && <GeoRegionSelector namePrefix="opportunity" />}

                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 8 }]}>
                      <TextAreaFormField
                        label="Description"
                        stretch
                        name="opportunity.description"
                        description="Describe details of the outcome intended for this opportunity."
                        required
                      />
                    </Grid>
                  </SpaceBetween>
                </ExpandableSection>
                {selectedOpportunityWorkstream?.name.match("Partner Offering") && (
                  <ExpandableSection defaultExpanded={true} variant="container" headerText="Solutions">
                    <SpaceBetween size="m">
                      <Toggle
                        checked={marqueeDesignWin}
                        onChange={(e) => {
                          marqueeDesignWinChange(e.detail.checked)
                        }}
                        description="If off, we default to Design Win"
                      >
                        Marquee Design Win
                      </Toggle>

                      <Toggle
                        checked={marketplaceOffer}
                        onChange={(e) => {
                          marketplaceOfferChange(e.detail.checked)
                        }}
                        description="If on, enter Marketplace URL to External Link field"
                      >
                        Marketplace Offer
                      </Toggle>

                      <SpaceBetween size="xxs">
                        <TextContent>
                          <strong>APN Solution Picker:</strong>
                        </TextContent>
                        <Multiselect
                          placeholder="Choose solutions to add as sfdc products"
                          options={apnProducts}
                          onChange={apnProductsChange}
                          deselectAriaLabel={(e) => `Remove ${e.label}`}
                          selectedOptions={selectedApnProducts}
                          disabled={productsLoading || productsError}
                          filteringType="auto"
                          empty={
                            accountProducts !== undefined
                              ? "This partner has no APN Solutions to add - ensure partner created the solution in Partner Central"
                              : "Choose an account to see available products"
                          }
                        />
                      </SpaceBetween>
                    </SpaceBetween>
                  </ExpandableSection>
                )}
                <ExpandableSection defaultExpanded={true} variant="container" headerText="Additional Details">
                  <SpaceBetween size="l">
                    <ColumnLayout columns={3}>
                      <DatePickerFormField
                        label="Close Date"
                        openCalendarAriaLabel={(selectedDate) => "Choose Date" + (selectedDate ? `, selected date is ${selectedDate}` : "")}
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                        name="opportunity.closeDate"
                        description="The tentative date of when the opportunity will be closed."
                        required
                      />

                      <SelectFormField
                        label="Stage"
                        name="opportunity.stageName"
                        options={stageOptions}
                        description="Adjust the stage through opportunity progression.  Must be Completed or Closed Lost with description when close date is reached."
                        required
                      />
                    </ColumnLayout>
                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 8 }]}>
                      <TextAreaFormField
                        label="Next Step"
                        stretch
                        name="opportunity.nextStep"
                        description="Defines the next steps to progress this opportunity"
                        required
                      />
                    </Grid>

                    <InputFormField
                      name="opportunity.externalLink"
                      inputMode="text"
                      label="External Link"
                      description="Enter a URL for any relevant content (i.e. Tech Business Plan)"
                    />
                  </SpaceBetween>
                </ExpandableSection>

                {!id && (
                  <Container header={<Header>Task (Activity)</Header>}>
                    <SpaceBetween size="l">
                      {tasks.map((_, index) => (
                        <Fieldset legend={`Task ${index + 1}`} key={`task-${index.toString()}`}>
                          <FieldContent opportunityData={oppFormValues} namePrefix={`tasks[${index}]`} hideRelatedPicker onInfoClicked={handleInfoClicked}>
                            <Button onClick={handleTaskDelete(index)} disabled={isSubmitting || isLoading}>
                              Delete
                            </Button>
                          </FieldContent>
                        </Fieldset>
                      ))}

                      <Button disabled={tasks.length > 2 || isSubmitting || isLoading} onClick={handleAddTaskClick} formAction="none">
                        Add Task
                      </Button>
                    </SpaceBetween>
                  </Container>
                )}
              </SpaceBetween>
            </Form>
          </form>
        )
      }}
    </Formik>
  )
}

export default FormContent
