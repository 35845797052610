import {
  Box,
  Button,
  ColumnLayout,
  DatePicker,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Link,
  Modal,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  Textarea,
} from "@amzn/awsui-components-react"
import { TaskRequest } from "api/tasks"
import GeoRegionSelector from "components/GeoRegionSelector"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { useProfile } from "context/ProfileContext"
import { Formik, FormikProps } from "formik"
import { CustomerOpportunity } from "models/customerOpportunity"
import { Opportunity } from "models/opportunity"
import { BD_ACTIVITY, SA_ACTIVITY, TASK_STATUS } from "models/task"
import moment from "moment"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { routeParams } from "routes"
import * as Yup from "yup"

import SA_ACTIVITY_MAPPING from "../models/activityMapping"

type props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  relatedItem: Opportunity | CustomerOpportunity
  relatedType: string | undefined
}

const NewGoalModal: React.FC<props> = ({ visible, setVisible, relatedItem: attachingTo, relatedType }) => {
  const { profileData } = useProfile()
  const { taskList, createTask, getTask, getOpportunity } = useOpportunityTaskContext()
  const setFlashMessages = useFlashbar()
  const navigate = useNavigate()

  // helper function to capitalize first letter of a string
  const capitalizeFirstLetter = (str: string | undefined) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : "")

  // set Formik innerRef and set Formik initialValue
  const formikRef = React.useRef<FormikProps<TaskRequest>>(null)

  const [initialValues, setInitialValues] = useState({
    status: "Completed",
    activityDate: moment().format("YYYY-MM-DD"),
    timeSpentHrs: "0",
    priority: "Normal",
    milestone: {
      name: "Execute",
      id: "100",
    },
  } as TaskRequest)

  useEffect(() => {
    setInitialValues((state) => ({ ...state, geo: profileData?.geo || "", region: profileData?.region || "" }))
  }, [profileData])

  // Formik validationSchema
  const validationSchema = Yup.object().shape({
    activityDate: Yup.string().required("You must select an Activity Date!").typeError("You must specify date in the format YYYY/MM/DD"),
    bdActivityType: Yup.string().required("You must select a BD Activity Type!"),
    description: Yup.string().when(["bdActivityType", "workstreamId", "status"], {
      is: (bdActivityType: string, workstreamId: string, status: string) =>
        bdActivityType === "PTR - External Technical Content" && workstreamId !== "ETC-10" && status !== "In Progress",
      then: (schema) =>
        schema
          .required()
          .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g,
            "Must provide a public URL! For example: https://aws.amazon.com"
          ),
    }),
    geo: Yup.string().required("You must select a Geo!"),
    region: Yup.string().required("You must select a Region!"),
    saActivity: Yup.string(),
    status: Yup.mixed()
      .oneOf(TASK_STATUS.map((item) => item))
      .required("You must select a Status!"),
    title: Yup.string()
      .required("Required")
      .when(["workstreamId"], {
        is: (workstreamId: string) => workstreamId === "ETC-10",
        then: (schema) =>
          schema.matches(/Content Security Review \(CSR\) - [A-Z][0-9]{9,10}/g, "Title must in format 'Content Security Review (CSR) - <SIM Ticket Id>'"),
      }),
  })

  // Formik onSubmit
  const submitHandler = async (values: TaskRequest) => {
    try {
      values["relatedId"] = attachingTo.id
      values["relatedType"] = relatedType || ""
      values["subject"] = profileData?.freeFormNames ? values["title"] : ""

      const newTask = await getTask((await createTask(values)).id)

      setFlashMessages([
        {
          content: (
            <Box>
              Task created successfully. View task details here:{" "}
              <Link
                variant="primary"
                onFollow={(e) => {
                  e.preventDefault()
                  navigate(routeParams.tasksDetails({ id: newTask.id }))
                }}
              >
                <text style={{ textDecorationLine: "underline" }}>{profileData?.freeFormNames ? newTask.subject : newTask.subject.split("-")[6]}</text>
              </Link>
            </Box>
          ),
          presist: true,
          type: "success",
        },
      ])

      setVisible(false)
      formikRef.current?.resetForm()
      setActivity(null)
    } catch (error) {
      console.log(error)
    }
  }

  const [activity, setActivity] = useState<SelectProps.Option | null>(null)

  return (
    <Modal
      size="medium"
      visible={visible}
      onDismiss={() => {
        setVisible(false)
        formikRef.current?.resetForm()
        setActivity(null)
      }}
      header={
        <Header variant="h2">
          Attaching to
          <br />
          &quot;{attachingTo.name ? attachingTo.name : ""}&quot;
        </Header>
      }
      footer={
        <Box float="right">
          <SpaceBetween size="s" direction="horizontal">
            <Button
              onClick={() => {
                setVisible(false)
                formikRef.current?.resetForm()
                setActivity(null)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={taskList.loading}
              onClick={() => {
                formikRef.current?.submitForm()
              }}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        innerRef={formikRef}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnChange={false}
      >
        {({ handleSubmit, errors, values, setFieldValue }: FormikProps<TaskRequest>) => (
          <form onSubmit={handleSubmit}>
            <Form>
              <SpaceBetween size="s">
                <FormField label="Activity">
                  <Select
                    disabled={taskList.loading}
                    selectedOption={activity || null}
                    filteringType="auto"
                    options={Object.entries(SA_ACTIVITY_MAPPING).map(([key, array]) => ({
                      label: key.replace("PTR - ", ""),
                      options: array.map((item) => ({ label: item.activityName, value: item.code, filteringTags: [item.saActivity] })),
                    }))}
                    onChange={(e) => {
                      setActivity(e.detail.selectedOption)
                      if ((e.detail.selectedOption.value || "").includes("(CSR)")) {
                        setFieldValue("title", e.detail.selectedOption.label + " - <SIM Ticket ID>")
                        setFieldValue("saActivity", "Other Thought Leadership [Thought Leadership]")
                        setFieldValue("workstreamId", "ETC-10")
                      } else {
                        setFieldValue("title", e.detail.selectedOption.label)
                        setFieldValue("workstreamId", e.detail.selectedOption.value)
                        setFieldValue("saActivity", e.detail.selectedOption.filteringTags ? e.detail.selectedOption.filteringTags[0] : undefined)
                      }

                      const bdMatches = Object.entries(SA_ACTIVITY_MAPPING).find(([key, array]) =>
                        array.find((item) => item.code === e.detail.selectedOption.value)
                      )
                      setFieldValue("bdActivityType", bdMatches ? bdMatches[0] : "")
                    }}
                  />
                </FormField>
                <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
                  <FormField label="Title" errorText={capitalizeFirstLetter(errors["title"])}>
                    <Input value={values.title} disabled={taskList.loading} onChange={(e) => setFieldValue("title", e.detail.value)} />
                  </FormField>
                  <FormField label="Status">
                    <RadioGroup
                      onChange={(e) => {
                        setFieldValue("status", e.detail.value)
                        if (e.detail.value == "In Progress") setFieldValue("activityDate", moment().add(1, "month").format("YYYY-MM-DD"))
                        if (e.detail.value == "Completed") setFieldValue("activityDate", moment().format("YYYY-MM-DD"))
                      }}
                      value={["In Progress", "Completed"].includes(values.status) ? values.status : ""}
                      items={["In Progress", "Completed"].map((item) => ({ label: item, value: item, disabled: taskList.loading }))}
                    />
                  </FormField>
                </Grid>
                <FormField label="Comments" errorText={capitalizeFirstLetter(errors["description"])}>
                  <Textarea value={values.description} disabled={taskList.loading} onChange={(e) => setFieldValue("description", e.detail.value)} />
                </FormField>
                <ExpandableSection headerText="Override Defaults">
                  <SpaceBetween size="s">
                    <FormField label="BD Activity Type" errorText={capitalizeFirstLetter(errors["bdActivityType"])}>
                      <Select
                        disabled={taskList.loading}
                        options={BD_ACTIVITY.map((item) => ({ label: item, value: item }))}
                        selectedOption={{ label: values.bdActivityType, value: values.bdActivityType }}
                        onChange={(e) => setFieldValue("bdActivityType", e.detail.selectedOption.value)}
                      />
                    </FormField>
                    <FormField label="SA Activity Type" errorText={capitalizeFirstLetter(errors["saActivity"])}>
                      <Select
                        disabled={taskList.loading}
                        options={SA_ACTIVITY.map((item) => ({ label: item, value: item }))}
                        selectedOption={{ label: values.saActivity, value: values.saActivity }}
                        onChange={(e) => setFieldValue("saActivity", e.detail.selectedOption.value)}
                      />
                    </FormField>
                    <GeoRegionSelector disabled={taskList.loading} />
                    <ColumnLayout columns={2}>
                      <FormField label="Activity Date" errorText={capitalizeFirstLetter(errors["activityDate"])} constraintText="Use YYYY/MM/DD format.">
                        {/* <DatePicker disabled={taskList.loading} value={values.activityDate} /> */}
                        <DatePicker disabled={taskList.loading} value={values.activityDate} onChange={(e) => setFieldValue("activityDate", e.detail.value)} />
                      </FormField>
                      <FormField label="Time Spend (Hrs)" errorText={capitalizeFirstLetter(errors["timeSpentHrs"])}>
                        <Input disabled={taskList.loading} value={values.timeSpentHrs} onChange={(e) => setFieldValue("timeSpentHrs", e.detail.value)} />
                      </FormField>
                    </ColumnLayout>
                    <FormField label="Status" errorText={capitalizeFirstLetter(errors["status"])}>
                      <Select
                        disabled={taskList.loading}
                        onChange={(e) => setFieldValue("status", e.detail.selectedOption.value)}
                        options={TASK_STATUS.map((item) => ({ label: item, value: item }))}
                        selectedOption={{ label: values.status, value: values.status }}
                      />
                    </FormField>
                  </SpaceBetween>
                </ExpandableSection>
              </SpaceBetween>
            </Form>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default NewGoalModal
