import { FormField, Grid, Select, SelectProps, TextContent } from "@amzn/awsui-components-react"
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import Link from "@amzn/awsui-components-react/polaris/link"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import GeoRegionSelector from "components/GeoRegionSelector"
import { useProfile } from "context/ProfileContext"
import { getIn, useFormikContext } from "formik"
import SA_ACTIVITY_MAPPING from "models/activityMapping"
import { Opportunity } from "models/opportunity"
import { BD_ACTIVITY, SA_ACTIVITY, TASK_PRIORITY, TASK_STATUS } from "models/task"
import { DatePickerFormField, InputFormField, SelectFormField, TextAreaFormField } from "pmsa-polaris/components/FormFields"
import useNamePrefix from "pmsa-polaris/hooks/useNamePrefix"
import { toOptions } from "pmsa-polaris/utils"
import { useCallback, useEffect, useState } from "react"

import RelatedToPicker from "../../components/RelatedToPicker"
import { TaskFormValues } from "./FormContent"
import topics from "./tools-topics"

interface Props {
  taskId?: string
  namePrefix?: string
  hideRelatedPicker?: boolean
  relatedToInitialOption?: SelectProps.Option
  relatedType?: string
  opportunityData?: Pick<Opportunity, "geo" | "region" | "workstreamName">
  onInfoClicked: (topic: keyof typeof topics) => (e: CustomEvent<BaseNavigationDetail>) => void
}

const STATUS_OPTIONS = toOptions(...TASK_STATUS)
const PRIORITY_OPTIONS = toOptions(...TASK_PRIORITY)

const FieldContent: React.FC<Props> = ({
  namePrefix,
  onInfoClicked,
  hideRelatedPicker,
  opportunityData,
  children,
  taskId,
  relatedToInitialOption,
  relatedType,
}) => {
  const { setFieldValue, values } = useFormikContext<TaskFormValues>()
  const { workstreamName, workstreamId, milestoneId, geo, region, activityDate, relatedId, title } = getIn(values, namePrefix ?? "", {})

  const getName = useNamePrefix(namePrefix)
  const [relatedOpportunityData, setRelatedOpportunityData] = useState(opportunityData)
  const [activity, setActivity] = useState<SelectProps.Option>()
  const { profileData } = useProfile()

  const disabled = !relatedId

  const generateSubject = useCallback(() => {
    if (profileData?.freeFormNames) {
      return title
    }

    const fiscalYear = activityDate && !Number.isNaN(activityDate) ? new Date(activityDate).getFullYear() : new Date().getFullYear()

    const fiscalYearString = `FY${fiscalYear.toString().substring(2)}`

    return [
      relatedOpportunityData?.geo ?? geo,
      relatedOpportunityData?.region ?? region,
      fiscalYearString,
      "PTNR",
      `${workstreamId || "[Activity]"}@${milestoneId || "[Milestone ID]"}`,
      title || "[Title]",
    ].join("-")
  }, [activityDate, geo, region, workstreamId, milestoneId, title, profileData, relatedOpportunityData])

  useEffect(() => {
    setFieldValue(getName("subject"), generateSubject())
  }, [setFieldValue, getName, generateSubject])

  useEffect(() => {
    if (workstreamId && workstreamName) {
      setActivity({ label: workstreamName, value: workstreamId })
      return
    }

    const workstreamEntry = Object.entries(SA_ACTIVITY_MAPPING).find(([_, array]) => array.some((item) => item.code === workstreamId))

    if (workstreamEntry) {
      const workstreamItem = workstreamEntry[1].find((item) => item.code === workstreamId)
      setActivity(workstreamItem ? { label: workstreamItem.activityName, value: workstreamId } : undefined)
    } else {
      setActivity(undefined)
    }
  }, [workstreamId, workstreamName])

  const handleRelatedSelected = useCallback(
    (type: string, data: any) => {
      const relatedTos = {
        opportunity: () => {
          setRelatedOpportunityData(data)
          setFieldValue(getName("relatedId"), data.id)
        },
        campaign: () => setFieldValue(getName("relatedId"), data.id),
        account: () => setFieldValue(getName("relatedId"), data.id),
      }

      relatedTos[type as keyof typeof relatedTos]?.()
    },
    [setFieldValue, getName]
  )

  const taskName = generateSubject()

  return (
    <SpaceBetween size="l">
      {!profileData?.freeFormNames && (
        <FormField label="Task Name" description="Formatted task name based on selections">
          <TextContent>
            <strong>{taskName}</strong>
          </TextContent>
        </FormField>
      )}

      {!hideRelatedPicker && (
        <Grid gridDefinition={[{ colspan: 8 }]}>
          <RelatedToPicker initialOption={relatedToInitialOption} initialType={relatedId ? "freeForm" : "myOpportunities"} onSelected={handleRelatedSelected} />
        </Grid>
      )}
      <FormField
        label="Activity"
        info={
          <Link variant="info" onFollow={onInfoClicked("helpWorkstreamType")}>
            Info
          </Link>
        }
        description="Choose the appropriate activity from the catalog"
      >
        <Select
          selectedOption={activity || null}
          disabled={disabled}
          filteringType="auto"
          options={Object.entries(SA_ACTIVITY_MAPPING).map(([key, array]) => ({
            label: key.replace("PTR - ", ""),
            options: array.map((item) => ({ label: item.activityName, value: item.code, filteringTags: [item.saActivity] })),
          }))}
          onChange={(e) => {
            setActivity(e.detail.selectedOption)
            if ((e.detail.selectedOption.value || "").includes("ETC-10")) {
              setFieldValue(getName("title"), e.detail.selectedOption.label + " - <Replace with SIM Ticket ID>")
              setFieldValue(getName("saActivity"), "Other Thought Leadership [Thought Leadership]")
              setFieldValue(getName("workstreamId"), "ETC-10")
              setFieldValue(getName("workstreamName"), e.detail.selectedOption.label)
            } else {
              setFieldValue(getName("title"), e.detail.selectedOption.label)
              setFieldValue(getName("saActivity"), e.detail.selectedOption.filteringTags ? e.detail.selectedOption.filteringTags[0] : undefined)
              setFieldValue(getName("workstreamId"), e.detail.selectedOption.value)
              setFieldValue(getName("workstreamName"), e.detail.selectedOption.label)
            }

            const bdMatches = Object.entries(SA_ACTIVITY_MAPPING).find(([key, array]) => array.find((item) => item.code === e.detail.selectedOption.value))
            setFieldValue(getName("bdActivityType"), bdMatches ? bdMatches[0] : "")
          }}
        />
      </FormField>

      <InputFormField name={getName("title")} inputMode="text" label="Title" disabled={disabled} description="Title" required />

      {!hideRelatedPicker && !profileData?.freeFormNames && <GeoRegionSelector disabled={disabled} save={!taskId} />}

      <ColumnLayout columns={3}>
        <DatePickerFormField
          label="Due date"
          openCalendarAriaLabel={(activityDate: string | null) => "Choose Date" + (activityDate ? `, selected date is ${activityDate}` : "")}
          nextMonthAriaLabel="Next month"
          placeholder="YYYY/MM/DD"
          previousMonthAriaLabel="Previous month"
          todayAriaLabel="Today"
          disabled={disabled}
          name={getName("activityDate")}
          description="The date of the actual activity"
          required
        />

        <InputFormField
          name={getName("timeSpentHrs")}
          inputMode="tel"
          disabled={disabled}
          label="Time Spent (Hrs)"
          description="The total time spent excluding travel to complete the activity."
        />
      </ColumnLayout>

      <ColumnLayout columns={3}>
        <SelectFormField
          name={getName("status")}
          label="Status"
          disabled={disabled}
          options={STATUS_OPTIONS}
          description="Status for organizing work during engagement"
          required
        />

        <SelectFormField
          name={getName("priority")}
          label="Priority"
          disabled={disabled}
          options={PRIORITY_OPTIONS}
          description="Manage the prioriy of the activity"
          required
        />
      </ColumnLayout>

      <SelectFormField
        filteringType="auto"
        name={getName("bdActivityType")}
        label="BD Activity Type"
        options={BD_ACTIVITY.map((item) => ({ label: item, value: item }))}
        disabled={disabled}
        description="The proper BD activity type selected as per the below Activity Tracking Guidelines."
      />

      <SelectFormField
        filteringType="auto"
        name={getName("saActivity")}
        label={"SA Activity"}
        options={SA_ACTIVITY.map((item) => ({ label: item, value: item }))}
        disabled={disabled}
      />

      <TextAreaFormField
        name={getName("description")}
        label="Comments"
        disabled={disabled}
        description="A third-party understandable description of the activity. Might need to include mandatory information as per Activity Tracking Guidelines."
      />

      {children}
    </SpaceBetween>
  )
}

export default FieldContent
