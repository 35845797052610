import { BaseOpportunityTask } from "./shared"

type TaskMilestone = {
  name: string
  id: string
}

export const TASK_STATUS = [
  "Completed",
  "Not Started",
  "In Progress",
  "Waiting on someone else",
  "Deferred",
  "Unresponsive",
  "Disqualified",
  "Cancelled",
] as const

export const TASK_PRIORITY = ["Normal", "High", "Low"] as const

export const TASK_TYPE = ["Other", "Call", "Meeting", "Email", "Live Chat", "AWSMP - SA"] as const

export const BD_ACTIVITY = [
  "PTR - External Technical Content",
  "PTR - Internal Technical Content",
  "PTR - Partner Programs Technical Validations",
  "PTR - Public Speaking",
  "PTR - Service Beta",
  "PTR - Strategic Enablement",
  "PTR - Technical Validations",
  "PTR - Miscellaneous",
]

export const SA_ACTIVITY = [
  "Solution Architecture Review [Architecture]",
  "Solution Demo [Architecture]",
  "Solution Prototype/PoC Support [Architecture]",
  "Customer Architecture Review [Architecture]",
  "Competency [Architecture]",
  "Foundational Technical Review [Architecture]",
  "Service Delivery/ Service Ready [Architecture]",
  "Technical Discovery Review [Architecture]",
  "Cloud Adoption Framework [Architecture]",
  "Customer Demo [Architecture]",
  "Migration Readiness Assessment [Architecture]",
  "Customer Prototype/PoC Support [Architecture]",
  "Well Architected [Architecture]",
  "Beta Demo [Architecture]",
  "Beta Prototype/PoC Support/PFR [Architecture]",
  "Migration Acceleration Program Support [Management]",
  "Cost Optimization [Cost]",
  "RFI and RFP response [Workshops]",
  "Statement of Work [SoW]",
  "Co-sell[Management]",
  "White Paper [Thought Leadership]",
  "Blog [Thought Leadership]",
  "General Tech Content [Thought Leadership]",
  "AOD/SME [Thought Leadership]",
  "Tutorial videos [Thought Leadership]",
  "Other Thought Leadership [Thought Leadership]",
  "Other Partner Content [Thought Leadership]",
  "POV [Thought Leadership]",
  "PR FAQ [Thought Leadership]",
  "Other Partner Content [Thought Leadership]",
  "POV [Thought Leadership]",
  "PR FAQ [Thought Leadership] ",
  "Public Speaking AWS Conference [Thought Leadership]",
  "Public Speaking Partner Conference [Thought Leadership]",
  "Other Public speaking [Thought Leadership]",
  "Webinars [Workshops]",
  "Immersion Day General [Workshops]",
  "Game Day [Workshops]",
  "AWSome Day [Workshops]",
  "Hackathon [Workshops]",
  "Other Workshop [Workshops]",
  "RFI and RFP response [Workshops]",
  "Webinars [Workshops]",
  "Solution Build/Development [Thought Leadership]",
]

export type Task = BaseOpportunityTask & {
  subject: string
  status: typeof TASK_STATUS[number]
  priority: typeof TASK_PRIORITY[number]
  accountName: string
  activityDate: string
  createdDate: string
  timeSpentHrs: string
  lastModifiedDate: string
  description: string
  bdActivityType: string
  opportunityName: string
  opportunityWorkstreamId?: string
  relatedId?: string
  relatedType: string
  relatedName?: string
  milestone: TaskMilestone
  type: string
  saActivity?: string
}

const TASK_CLOSED_ARRAY: Task["status"][] = ["Completed", "Disqualified", "Unresponsive"]

export const TASK_CLOSED_NAMES = new Set(TASK_CLOSED_ARRAY)
